@import './colors-functions.scss';

.text-gray {
  color: useColor('--colorDarkGrayishBlue');
}
.text-gray2 {
  color: useColor('--colorGrayishBlue');
}

.text-gray--40 {
  color: useColor('--colorDarkGrayishBlue', 0.4);
}
.text-accent {
  color: useColor('--colorAccent', 1);
}
.text-black--40 {
  color: useColor('--colorDarkBlue', 0.4);
}
.text-dark {
  color: useColor('--colorDarkBlue');
}
.bg-accent {
  background-color: useColor('--colorAccent', 1);
}
.bg-light {
  background-color: useColor(--colorWhite);
}

.text-success {
  color: useColor(--colorPositive);
}

.text-critical {
  color: useColor(--colorCritical);
}

:root {
  @each $name, $rgb in $colors {
    --#{$name}: #{$rgb};
  }
}
