.pay-with-near-toast {
  & > div {
    background: #fff3f3;
    border-radius: 11px;
    color: #e0335d;
    font-size: 13px;
    box-shadow: none;
    padding: 10px 30px;
    min-width: auto;
  }
}
